var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs is-boxed is-right"},[_c('ul',[_c('li',{class:{
        'is-active': _vm.tab === 'account-history-companion'
      },attrs:{"data-test-id":"product-tabs-companion"}},[_c('a',{class:{ 'p-4': _vm.$mq === 'mobile' },on:{"click":function($event){return _vm.$emit('change-tab', 'account-history-companion')}}},[(!_vm.$motors)?_c('span',{staticClass:"is-hidden-mobile has-text-weight-semibold"},[_vm._v("Companion ")]):_c('span',{class:[
            'is-hidden-mobile',
            _vm.tab === 'account-history-companion' ? 'has-text-weight-bold' : 'has-text-weight-medium',
          ]},[_vm._v("Price check ")]),_c('img',{staticClass:"is-hidden-tablet icon is-marginless is-small",attrs:{"src":require(`core/img/icons/percayso-logo.svg`)}})])]),(_vm.hasAutoCheck)?_c('li',{class:{
        'is-active': _vm.tab === 'account-history-check'
      },attrs:{"data-test-id":"product-tabs-autocheck"}},[(_vm.hasAutoCheck)?_c('a',{class:{ 'p-4': _vm.$mq === 'mobile' },on:{"click":function($event){return _vm.$emit('change-tab', 'account-history-check')}}},[(!_vm.$motors)?_c('span',{staticClass:"is-hidden-mobile has-text-weight-semibold"},[_vm._v("AutoCheck")]):_c('span',{class:[
            'is-hidden-mobile',
            _vm.tab === 'account-history-check' ? 'has-text-weight-bold' : 'has-text-weight-medium',
          ]},[_vm._v("History check")]),_c('i',{staticClass:"fas fa-check is-hidden-tablet icon is-marginless is-small",class:[
            _vm.tab === 'account-history-check'
              ? 'has-text-info'
              : 'has-text-light'
          ]})]):_vm._e()]):_vm._e(),(!_vm.$motors)?_c('li',{class:{ 'is-active': _vm.tab === 'account-history-vpp' },attrs:{"data-test-id":"product-tabs-vpp"}},[_c('a',{class:{ 'p-4': _vm.$mq === 'mobile' },on:{"click":function($event){return _vm.$emit('change-tab', 'account-history-vpp')}}},[_c('span',{staticClass:"is-hidden-mobile has-text-weight-semibold"},[_vm._v(" Vehicle Profile ")]),_c('i',{staticClass:"fas fa-search is-hidden-tablet icon is-marginless is-small",class:[
            _vm.tab === 'account-history-vpp' ? 'has-text-info' : 'has-text-light'
          ]})])]):_vm._e(),(_vm.hasMotorCheck)?_c('li',{class:{ 'is-active': _vm.tab === 'account-history-motorcheck' },attrs:{"data-test-id":"product-tabs-motorcheck"}},[_c('a',{class:{ 'p-4': _vm.$mq === 'mobile' },on:{"click":function($event){return _vm.$emit('change-tab', 'account-history-motorcheck')}}},[_c('span',{staticClass:"is-hidden-mobile has-text-weight-semibold"},[_vm._v(" MotorCheck ")]),_c('i',{staticClass:"fas fa-car is-hidden-tablet icon is-marginless is-small",class:[
            _vm.tab === 'account-history-motorcheck' ? 'has-text-info' : 'has-text-light'
          ]})])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }